<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicle_groups">Gruppen</router-link></li>
              <li>{{ vehicle_group.name }}</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicle_groups" class="button button-grey button-small">Alle Gruppen ›</router-link></p>
            </div>
          </header>

          <form @submit="update_vehicle_group" class="form" method="post" style="margin-top:20px">

            <div class="form-wrap">
              <label for="name">Name</label>
              <input v-model="vehicle_group.name" class="form-input" type="text" name="name" id="name" placeholder="Name">
            </div>

            {{ errors }}

            <p>
              <input type="submit" value="Speichern ›" class="button button-orange button-small">
            </p>


          </form>


        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      errors: [],
      vehicle_group: {}
    }
  },
  methods: {
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_groups/'+this.$route.params.id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.vehicle_group = response.data.vehicle_group;
      })
      .catch(error => {
        console.log(error);
      })

    },
    update_vehicle_group: function (e) {
      e.preventDefault();

      axios.patch(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_groups/'+this.$route.params.id, this.vehicle_group, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$router.push('/admin/vehicle_groups');
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
